import { Box, Heading, Image, Paragraph } from 'grommet'
import React from 'react'
//REDUX
import { useResponsive } from '../../hooks'

import { faTruck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const getImageURL = (brand, model, image) => {
  let brandCleaned = brand?.toLowerCase().replace(/ /g, '') || ''
  let modelCleaned =
    model
      ?.toLowerCase()
      .replace(/ /g, '')
      .replace('+', '') || ''

  return `https://blike.imgix.net/grid/${brandCleaned}/${modelCleaned}/${'default.jpg'}?w=600`
}

const BikeItem = ({
  top = false,
  right = false,
  purchaseType = 'subscription',
  recycle = false,
  bike: {
    id,
    brand,
    model,
    image,
    features,
    price,
    leadTime,
    leadTimeLight,
    subPrice,
    subPriceWithLock,
  },
}) => {
  return (
    <Box
      pad={{
        top: top ? 'none' : 'medium',
        horizontal: 'medium',
        bottom: 'medium',
      }}
      style={{ position: 'relative' }}
    >
      {!right && (
        <Box
          style={{
            width: 1,
            position: 'absolute',
            right: 0,
            height: '80%',
            top: '10%',
            borderLeft: '1px solid rgba(124,124,124,0.15)',
          }}
        />
      )}
      <Box
        style={{
          height: 1,
          position: 'absolute',
          bottom: 0,
          width: '80%',
          left: '10%',
          borderBottom: '1px solid rgba(124,124,124,0.15)',
        }}
      />
      <Box height="200px">
        {brand && model && (
          <Image fill fit={'contain'} src={getImageURL(brand, model, image)} />
        )}
      </Box>

      <Box fill style={{ overflow: 'hidden' }}>
        {recycle && (
          <Paragraph margin={{ top: 'medium', bottom: 'none' }} color="brand">
            ReCycle
          </Paragraph>
        )}
        <Paragraph
          size="medium"
          className="bold"
          style={{ whiteSpace: 'nowrap' }}
          margin={{ top: recycle ? 'none' : 'medium', bottom: 'xsmall' }}
        >
          {`${brand}  ${model.replace('plus', '+')}`}
        </Paragraph>
      </Box>

      {features &&
        features.map((feature, index) => (
          <Paragraph key={index} size="xsmall" margin="none" className="light">
            {feature}
          </Paragraph>
        ))}

      <Box width={{ min: '150px' }}>
        <Heading
          responsive={false}
          level={4}
          size={'small'}
          margin={{ top: 'small', horizontal: 'none', bottom: 'none' }}
          color="black"
          style={{ lineHeight: 1, letterSpacing: 0.8 }}
        >
          {purchaseType === 'subscription'
            ? `£${subPriceWithLock?.toFixed(0)}`
            : price}
        </Heading>
        <Paragraph
          size={'xsmall'}
          margin="none"
          color="#999999"
          style={{ lineHeight: 1 }}
        >
          {purchaseType === 'subscription' ? (
            <>
              <Paragraph size="xsmall" margin="none">
                per month insured and maintained
              </Paragraph>
              <Paragraph size="xsmall" margin="none">
                plus one-off {recycle ? '£48' : "£100"} joining fee
              </Paragraph>
              <Paragraph size="xsmall" margin="none">
                Min. term 1 month; own after 36 months
              </Paragraph>
            </>
          ) : (
            'per month after tax insured and maintained'
          )}
        </Paragraph>
      </Box>

      <Box
        direction="row"
        align="end"
        justify="start"
        margin={{ top: 'small' }}
      >
        <FontAwesomeIcon
          icon={faTruck}
          size={'xs'}
          color={recycle ? 'green' : leadTimeLight}
        />
        <Paragraph
          size="xsmall"
          margin={{ left: 'xsmall', vertical: 'none' }}
          className="light"
        >
          {recycle ? 'From 14 - 21 days' : leadTime}
        </Paragraph>
      </Box>
    </Box>
  )
}

export default BikeItem
